/* generated by pull.js */
const manifest = {
  "editorOnly": true,
  "name": "Insert blocks by name",
  "description": "Middle click on the code area, use Ctrl+Space or Shift+Space to bring up a floating input box where you can type the name of a block (or parts of it) and drag the block into the code area. Hold Shift while dragging to avoid closing the box when adding multiple blocks at once.",
  "credits": [
    {
      "name": "griffpatch"
    },
    {
      "name": "TheColaber",
      "link": "https://scratch.mit.edu/users/TheColaber/"
    }
  ],
  "tags": [
    "recommended"
  ],
  "enabledByDefault": true,
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "userstyles": [
    {
      "url": "userstyle.css"
    }
  ],
  "info": [
    {
      "text": "This addon was previously part of the \"developer tools\" addon but has moved here.",
      "id": "developer-tools"
    }
  ]
};
export default manifest;
